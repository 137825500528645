@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  line-height: 18px;
  padding: 0;
  margin: 0;
  color: white;
  box-sizing: border-box;
}

body {
  background-color: black;
}

a {
  text-decoration: none;
  color: #019F66;
}

.sub_head {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #9f9f9f;
  margin-bottom: 35px;
}

.head {
  text-align: center;
  font-size: 46px;
  font-weight: 700;
  color: #f0f0f0;
}

.text {
  color: #9f9f9f;
  font-weight: 400;
  line-height: 29px;
  font-size: 17px;
}



 /* Animations */
 .hide-modal-anim {
  animation: hides .4s forwards;
  -webkit-animation: hides .4s forwards;
}
/* Fade in Animation */
.ease-in {
  animation: ease-in 0s;
  -webkit-animation: ease-in 0s;
}
.material-icons.cached {
  animation: rotate 1s infinite;
  -webkit-animation: rotate 1s infinite;
  text-align: center;
  margin: 0 auto;
}

.rotate {
  animation: rotate 1s forwards;
  -webkit-animation: rotate 1s forwards;
}
.skeleton {
  background: linear-gradient(90deg, #eeeeee, #dadada, #ebebeb);
  background-size: 200%;
  animation: skeleton 1s ease-in-out infinite;
  -webkit-animation: skeleton 1s ease-in-out infinite;
}
.skeleton.card {
  height: 140px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

@keyframes rotate {
  from {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
.reveals {
  animation: reveals .7s ease-in-out;
  -webkit-animation: reveals .7s ease-in-out;
}
@keyframes reveals {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hides {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}

.come-down {
  animation: come-down .5s ease-in-out;
  -webkit-animation: come-down .5s ease-in-out;
}

@keyframes come-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
  }
    100% {
      opacity: 1;
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
  }
}

.come-up {
  animation: come-up .3s ease-in-out;
  -webkit-animation: come-up .3s ease-in-out;
}

@keyframes come-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
}
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
}

.modal-down {
  animation: modal-down .3s ease-in-out forwards;
  -webkit-animation: modal-down .3s ease-in-out forwards;
}

.side-modal-down {
  animation: side-modal-down .3s ease-in-out forwards;
  -webkit-animation: side-modal-down .3s ease-in-out forwards;
}

@keyframes modal-down {
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
  100% {
    opacity: 0;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
}
}

@keyframes side-modal-down {
  0% {
    opacity: 1;
    transform: translateX(0vw);
    -webkit-transform: translateX(0vw);
    -moz-transform: translateX(0vw);
    -ms-transform: translateX(0vw);
    -o-transform: translateX(0vw);
  }
  100% {
    opacity: 0;
    transform: translateX(40px);
    -webkit-transform: translateX(40px);
    -moz-transform: translateX(40px);
    -ms-transform: translateX(40px);
    -o-transform: translateX(40px);
}
}

@keyframes fadeout {
  0% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
  100% {
    opacity: 0;
    transform: scale(3);
  }
}

@keyframes ease-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateX(-10vw);
    -webkit-transform: translateX(-10vw);
    -moz-transform: translateX(-10vw);
    -ms-transform: translateX(-10vw);
    -o-transform: translateX(-10vw);
}
  100% {
    opacity: 1;
    transform: translateX(0vw);
    -webkit-transform: translateX(0vw);
    -moz-transform: translateX(0vw);
    -ms-transform: translateX(0vw);
    -o-transform: translateX(0vw);
}
}

.fade-in {
  animation: slide-in .8s forwards;
  -webkit-animation: slide-in .8s forwards;
}
.fadeout {
  animation: slideout .8s forwards;
  -webkit-animation: slideout .8s forwards;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(40px);
    -webkit-transform: translateX(40px);
    -moz-transform: translateX(40px);
    -ms-transform: translateX(40px);
    -o-transform: translateX(40px);
}
  100% {
    opacity: 1;
    transform: translateX(0vw);
    -webkit-transform: translateX(0vw);
    -moz-transform: translateX(0vw);
    -ms-transform: translateX(0vw);
    -o-transform: translateX(0vw);
}
}

@keyframes slide-out {
  0% {
    opacity: 0;
    transform: translateX(-40px);
    -webkit-transform: translateX(-40px);
    -moz-transform: translateX(-40px);
    -o-transform: translateX(-40px);
    -ms-transform: translateX(-40px);
}
  100% {
    opacity: 1;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    -ms-transform: translateX(0px);
}
}

@keyframes increasewidth {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes increasewidthsmaller {
  0% {
    width: 0%;
  }
  100% {
    width: 60%;
  }
}
.roll-zoomin {
  animation: reveals-roll .3s ease-in-out forwards;
  -webkit-animation: reveals-roll .3s ease-in-out forwards;
}
@keyframes reveals-roll {
  0% {
    border-radius: 100vh;
    opacity: 0;
    transform: scale(0);
    -webkit-border-radius: 100vh;
    -moz-border-radius: 100vh;
    -ms-border-radius: 100vh;
    -o-border-radius: 100vh;
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    border-radius: 0px;
    opacity: 1;
    transform: scale(1);
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}
}

.blur-in {
  animation: blur-in 0.5s ease-in-out forwards;
}
.blur-out {
  animation: blur-out 0.5s ease-in-out forwards;
}

@keyframes blur-in {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(20px);
  }
}

@keyframes blur-out {
  0% {
    filter: blur(20px);
    -webkit-filter: blur(20px);
}
  100% {
    filter: blur(0px);
    -webkit-filter: blur(0px);
}
}

@keyframes skeleton {
  0% {
    background-position:  0% 10%;
  }
  50% {
    background-position:  100% 100%;
  }
  100% {
    background-position:  0% 0%;
  }
}

/* // Hide Number Arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Page Transitions */
.page-enter-active,
.page-leave-active {
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
}
.page-enter,
.page-leave-to {
  opacity: 0;
}
