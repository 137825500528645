
.blog_inner {
  max-width: 1344px;
  padding-top: 8rem;
  padding-bottom: 4rem;
  width: 80%;
  margin: auto;
}

.blog_content {
  margin-top: 6rem;
}

.react-loading-skeleton {
  margin-bottom: 2rem;
}

.blog_items_ctn {
  display: flex;
  width: 100%;
  padding: 50px;
  /* height: 30vh; */
  background-color: rgba(23, 23, 23, 0.859);
  /* border: 1px solid red; */
  border-radius: 20px;
  margin-bottom: 30px;
}

.item_image {
  display:block;
  flex-basis: 40%;
  /* background-position: 0; */
  /* height: 100%; */
  border-radius: 20px;
  /* filter: grayscale(); */
}

.item_content {
  flex-basis: 80%;
  padding: 10px 3vw;
}

.item_header {
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 20px;
}

.item_text {
  font-size: 16px;
  line-height: 24px;
  /* margin-bottom: 30px; */
}

.item_btn {
  border: none;
  height: 50px;
  padding: 0 20px;
  /* width: 8rem; */
  background-color: #019F66;
  border-radius: 30px;
  margin-top: 20px;
  display: flex;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.item_btn span {
  margin-left: 10px;
}

.item_link {
  display: block;
  width: fit-content;
}

.btn_span img {
  width: 22px;
}


@media only screen and (max-width: 1200px) {
  .blog_inner {
    width: 90%;
  }
}
@media only screen and (max-width: 900px) {
  .item_image {
    display: none;
  }
  .blog_items_ctn {
    flex-direction: column;
    padding: 25px;
  }
}
@media only screen and (max-width: 500px) {
  .item_header {
    font-size: 20px;
    line-height: 30px;
  }
  .item_text {
    font-size: 14px;
  }
}