.hero_ctn {
  height: 80vh;
  display: flex;
  align-items: center;
}

.hero_inner {
  max-width: 1344px;
  width: 45%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avatar {
  text-align: center;
  margin: auto;
  margin-bottom: 40px;
  width: 30%;
}

.hero_head {
  color: #fff;
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
}

.spanned_text {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.hero_text {
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
  line-height: 26px;
}

.btns {
  display: flex;
  margin-top: 40px;
}

.global_btn {
  color: white;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0 10px;
}

.btn_2 {
  background-color: #fff;
  color: #0c0c0c;
}

.arrow_right {
  color: #0c0c0c;
  margin-left: 6px;
}

@media only screen and (max-width: 900px) {
  .hero_inner {
    width: 85%;
  }
}

@media only screen and (max-width: 500px) {
  .spanned_text {
    display: none;
  }
  .avatar {
    width: 50%;
  }
  .hero_head {
    font-size: 40px;
  }
  .hero_text {
    font-size: 14px;
  }
}