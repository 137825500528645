.container {
  background-image: url('../assets/images/black-rocks.jpg');
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ctn_overlay {
  background: rgba(0, 0, 0, 0.556);
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.username {
  text-align: center;
  font-size: 65px;
  font-weight: 800;
}

.user_skills {
  text-align: center;
  margin-top: 4rem;
}

@media only screen and (max-width: 500px) {
  .username {
    font-size: 55px;
    font-weight: 800;
  }
}