.footer_ctn {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: transparent;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 40px;
  margin-bottom: 40px;
  height: 12vh;
  justify-content: space-between;
  background-color: transparent;
}

.social_icon {
  font-size: 20px;
  background-color: transparent;
}