.resume_inner {
  max-width: 1344px;
  padding-top: 8rem;
  padding-bottom: 4rem;
  width: 90%;
  margin: auto;
}

.resume_content {
  display: flex;
  margin-top: 8rem;
  justify-content: space-between;
}

.side_head {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 30px;
}

.edu_head {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 25px;
}

.edu_sub_head {
  font-size: 14px;
  font-weight: 500;
  color: #9f9f9f;
}

.education_ctn {
  flex-basis: 47%;
}

.education_ctn_inner {
  border-left: 3px solid #019F66;
  background-color: #161616;
}

.education_item {
  display: flex;
  padding: 30px 20px 30px 0;
  border-bottom: 0.1px solid #9f9f9f50;
}

.edu_arrow {
  margin-right: 30px;
}

.edu_arrow img {
  width: 25px;
}

.company_name {
  color: #019F66;
  margin-right: 10px;
}

.list_spec {
  margin-top: 10px;
  color: #9f9f9f;
  padding-left: 30px;
}

.list_spec li {
  color: #9f9f9f;
  font-weight: 400;
  line-height: 34px;
  font-size: 15px;
}

@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 900px) {
  .resume_content {
    flex-direction: column-reverse;
  }

  .edu_ctn {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 500px) {
}