.port_inner {
  max-width: 1344px;
  padding-top: 8rem;
  padding-bottom: 4rem;
  width: 90%;
  margin: auto;
}

.port_tab {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.port_content {
  margin-top: 6rem;
}

.port_tab p {
  margin: 0 10px;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}

.port_tab .activeTab {
  color: #009e66;
  border-bottom: 2px solid #009e66;
}

.port_tab p:hover {
  color: #009e66;
  border-bottom: 2px solid #009e66;
}

.port_items_ctn {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 30px;
  flex-wrap: wrap;
}

.loader_ctn > span {
  display: flex;
  margin-top: 30px;
}

.loader_ctn > span > .react-loading-skeleton {
  margin-right: 30px;
  height: 25rem;
}

.port_item {
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 31%;
  height: 25rem;
  margin-bottom: 20px;
  margin-right: 1%;
  background-repeat: no-repeat;
  background-position: center;
  background: #00000065;
  border: 2px solid #fff;
}

.port_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 31%;
  width: 100%;
  height: 100%;
  /* margin-bottom: 20px; */
  background-repeat: no-repeat;
}

.port_details:hover {
  background: #000000de;
}

.port_type {
  font-weight: 600;
  margin-top: 10px;
  color: #009e66;
  color: transparent;
}

.port_name {
  color: transparent;
}

.port_item:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.port_item:hover .port_type {
  color: #009e66;
}

.port_item:hover .port_name {
  color: unset;
}

@media only screen and (max-width: 1200px) {
  .port_item {
    height: 18rem;
  }
}
@media only screen and (max-width: 900px) {
  .port_item {
    flex-basis: 47%;
    height: 20rem;
    margin-bottom: 30px;
  }
  .loader_ctn > span {
    
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  .loader_ctn > span > .react-loading-skeleton {
    margin-right: 10px;
    width: 47%;
    height: 20rem;
  }
}
@media only screen and (max-width: 500px) {
  .port_item {
    flex-basis: 48%;
    height: 10rem;
    margin-bottom: 30px;
  }
}