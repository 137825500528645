.header_ctn {
  position: fixed;
  width: 100vw;
  /* background-color: #000000; */
}

.header_ctn_extended {
  z-index: 10;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.header_inner {
  max-width: 1344px;
  margin: auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.logo {
  width: 10rem;
}

.menu {
  display: flex;
  flex-basis: 30%;
  justify-content: space-between;
}

.menu_item {
  color: white;
}

.mobile_bar {
  display: none;
}

.hamburger {
  font-size: 30px;
}

/* MOBILE CONTAINER */

.hide_menu {
  display: none;
}

.menu-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mobile_menu_inner {
  padding-top: 5vh;
}

.menu-text {
  padding: 45px 20px;
  color: #a8a8a8;
  font-size: 36px;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.169);
}


@media only screen and (max-width: 1200px) {
  .menu {
    flex-basis: 40%;
  }
}
@media only screen and (max-width: 900px) {
  .menu {
    display: none;
  }

  .mobile_bar {
    display: block;
  }
}

@media only screen and (max-width: 500px) {
  .header_inner {
    height: 100px;
  }
}