.about_inner {
  max-width: 1344px;
  padding-top: 8rem;
  padding-bottom: 4rem;
  width: 90%;
  margin: auto;
}

.about_content {
  display: flex;
  margin-top: 6rem;
}

.lhs {
  flex-basis: 37%;
  margin-right: 6%;
}

.rhs {
  flex-basis: 57%;
}

.user_image {
  width: 100%;
}

.about_text_1 {
  color: #009e66;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.about_text_2 {
  font-size: 31px;
  font-weight: 700;
  line-height: 50px;
}

.text {
  margin-top: 20px;
}

.line {
  border: none;
  border-top: 2px solid rgba(255,255,255,.08);
  margin: 3rem 0;
}

.info {
  color: #dadada;
  font-size: 15px;
  font-weight: 500;

}

.sub_info {
  margin-left: 15px;
}

.info_ctn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.donwnload_btn {
  background-color: #009e66;
  color: white;
  border-radius: 50px;
  height: 50px;
  width: 13rem;
  border: none;
  margin-top: 40px;
  cursor: pointer;
}

.service_section {
  margin-top: 8rem;
}

.service_content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.service_sub_head, .service_head {
  text-align: unset;
}

.service_item {
  margin-top: 70px;
  flex-basis: 31%;
  background-color: #161616;
  padding: 3rem;
  transition: transform .7s;
}

.service_item:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 
}

.service_item_head {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.service_item_text {
  color: #9f9f9f;
  font-weight: 400;
  line-height: 29px;
  font-size: 16px;
}

.service_icon {
  color: #009e66;
  font-size: 50px;
}

.testimonial_section {
  margin-top: 8rem;
}

.testimonial_content {
  margin-top: 70px;
}

.testimonial_slider {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.testimonial_item {
  /* display: flex; */
  background-color: #161616;
  padding: 50px;
  width: 48%;
  border-radius: 10px;
}

.testi_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.testi_image {
  flex-basis: 10%;
  margin-right: 20px;
}

.testi_image img{
  border-radius: 100%;
  width: 100%;
}

.first_side {
  display: flex;
  align-items: center;
}

.testi_name {
  font-size: 15px;
  font-weight: 500;
}

.testi_role {
  color: #9f9f9f;
  font-weight: 400;
  line-height: 29px;
  font-size: 15px;
}


.testi_icon {
  color: #009e66;
  font-size: 30px;
}


@media only screen and (max-width: 1200px) {
  .service_item {
    flex-basis: 47%;
  }
}
@media only screen and (max-width: 900px) {
  .testimonial_item {
    width: 100%;
    margin-bottom: 40px;
  }

  .service_item {
    padding: 2.5rem;
  }
}
@media only screen and (max-width: 500px) {
  .about_content {
    flex-direction: column;
  }

  .lhs {
    margin-bottom: 60px;
  }
  .service_item {
    flex-basis: 100%;
  }

  .about_text_2 {
    font-size: 22px;
    line-height: 34px;
  }

  .text {
    font-size: 15px;
  }

  .info_ctn {
    flex-direction: column;
    margin-bottom: 0;
  }
}
